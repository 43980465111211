// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tTfJ0rYbIzGax_tEyTfF {
  margin: 50px 0px;
  font-size: 16px;
}
.tTfJ0rYbIzGax_tEyTfF div {
  margin: 10px 0px;
}
.tTfJ0rYbIzGax_tEyTfF label {
  display: inline-block;
  font-weight: bold;
  margin-right: 10px;
  width: 180px;
  text-transform: uppercase;
}
.tTfJ0rYbIzGax_tEyTfF input {
  padding: 5px 10px;
  width: 260px;
}
.tTfJ0rYbIzGax_tEyTfF button {
  margin-top: 40px;
}
.tTfJ0rYbIzGax_tEyTfF .OQSRxQcdJUsou2l5ShiT {
  margin-left: 25px;
  margin-top: 0px;
}

`, "",{"version":3,"sources":["webpack://./src/styles/members.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,yBAAyB;AAC3B;AACA;EACE,iBAAiB;EACjB,YAAY;AACd;AACA;EACE,gBAAgB;AAClB;AACA;EACE,iBAAiB;EACjB,eAAe;AACjB","sourcesContent":[".membersWrapper {\n  margin: 50px 0px;\n  font-size: 16px;\n}\n.membersWrapper div {\n  margin: 10px 0px;\n}\n.membersWrapper label {\n  display: inline-block;\n  font-weight: bold;\n  margin-right: 10px;\n  width: 180px;\n  text-transform: uppercase;\n}\n.membersWrapper input {\n  padding: 5px 10px;\n  width: 260px;\n}\n.membersWrapper button {\n  margin-top: 40px;\n}\n.membersWrapper .memberSearch {\n  margin-left: 25px;\n  margin-top: 0px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"membersWrapper": `tTfJ0rYbIzGax_tEyTfF`,
	"memberSearch": `OQSRxQcdJUsou2l5ShiT`
};
module.exports = ___CSS_LOADER_EXPORT___;
