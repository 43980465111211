// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sp_9GPVhZhNV1ivlG1gh {
  margin: 50px 0px;
  font-size: 16px;
}
.Sp_9GPVhZhNV1ivlG1gh div {
  margin: 20px 0px;
}
.Sp_9GPVhZhNV1ivlG1gh label {
  display: inline-block;
  font-weight: bold;
  margin-right: 10px;
  width: 120px;
  text-transform: uppercase;
}
.Sp_9GPVhZhNV1ivlG1gh input {
  padding: 5px 10px;
  width: 200px;
}
.Sp_9GPVhZhNV1ivlG1gh button {
  margin-top: 40px;
}

`, "",{"version":3,"sources":["webpack://./src/styles/login.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,yBAAyB;AAC3B;AACA;EACE,iBAAiB;EACjB,YAAY;AACd;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".loginWrapper {\n  margin: 50px 0px;\n  font-size: 16px;\n}\n.loginWrapper div {\n  margin: 20px 0px;\n}\n.loginWrapper label {\n  display: inline-block;\n  font-weight: bold;\n  margin-right: 10px;\n  width: 120px;\n  text-transform: uppercase;\n}\n.loginWrapper input {\n  padding: 5px 10px;\n  width: 200px;\n}\n.loginWrapper button {\n  margin-top: 40px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginWrapper": `Sp_9GPVhZhNV1ivlG1gh`
};
module.exports = ___CSS_LOADER_EXPORT___;
