// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.onF5Yy5VSgXRXanS8SS4 {
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
}
.onF5Yy5VSgXRXanS8SS4 a {
  padding: 10px;
  text-transform: uppercase;
  font-weight: bold;
}
.onF5Yy5VSgXRXanS8SS4 button {
  padding: 10px;
  border: none;
  text-transform: uppercase;
  background: none;
  font-weight: bold;
  font-size: 14px;
  color: #aaa;
}
.T1gekTfJ8FMjvLblY2AH {
  padding-top: 10px;
  padding-bottom: 20px;
}
.T1gekTfJ8FMjvLblY2AH a {
  padding: 10px;
  text-transform: uppercase;
  font-weight: bold;
}

`, "",{"version":3,"sources":["webpack://./src/styles/navigation.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,oBAAoB;AACtB;AACA;EACE,aAAa;EACb,yBAAyB;EACzB,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,YAAY;EACZ,yBAAyB;EACzB,gBAAgB;EAChB,iBAAiB;EACjB,eAAe;EACf,WAAW;AACb;AACA;EACE,iBAAiB;EACjB,oBAAoB;AACtB;AACA;EACE,aAAa;EACb,yBAAyB;EACzB,iBAAiB;AACnB","sourcesContent":[".navigation {\n  border-bottom: 1px solid #ccc;\n  padding-bottom: 20px;\n}\n.navigation a {\n  padding: 10px;\n  text-transform: uppercase;\n  font-weight: bold;\n}\n.navigation button {\n  padding: 10px;\n  border: none;\n  text-transform: uppercase;\n  background: none;\n  font-weight: bold;\n  font-size: 14px;\n  color: #aaa;\n}\n.subNavigation {\n  padding-top: 10px;\n  padding-bottom: 20px;\n}\n.subNavigation a {\n  padding: 10px;\n  text-transform: uppercase;\n  font-weight: bold;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navigation": `onF5Yy5VSgXRXanS8SS4`,
	"subNavigation": `T1gekTfJ8FMjvLblY2AH`
};
module.exports = ___CSS_LOADER_EXPORT___;
